// BACKEND SERVER
// export const SERVERADDRESS = "http://localhost:8000";
export const SERVERADDRESS = "https://riyowork.herokuapp.com";


// USER LOGIN CONST
export const USER_AUTH_REQUEST = "USER_AUTH_REQUEST";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_AUTH_FAIL = "USER_AUTH_FAIL";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";


// SHIFT DATA CONST
export const SHIFT_DATA_REQUEST = "SHIFT_DATA_REQUEST";
export const SHIFT_DATA_SUCCESS = "SHIFT_DATA_SUCCESS";
export const SHIFT_DATA_FAIL = "SHIFT_DATA_FAIL";
export const SHIFT_DASBOARD_REQUEST = "SHIFT_DASBOARD_REQUEST";
export const SHIFT_DASBOARD_SUCCESS = "SHIFT_DASBOARD_SUCCESS";
export const SHIFT_DASBOARD_FAIL = "SHIFT_DASBOARD_FAIL";
export const SHIFT_PROFILE_REQUEST = "SHIFT_PROFILE_REQUEST"
export const SHIFT_PROFILE_SUCCESS = "SHIFT_PROFILE_SUCCESS"
export const SHIFT_PROFILE_FAIL = "SHIFT_PROFILE_FAIL"
export const SHIFT_SEARCH_REQUEST = "SHIFT_SEARCH_REQUEST"
export const SHIFT_SEARCH_SUCCESS = "SHIFT_SEARCH_SUCCESS"
export const SHIFT_SEARCH_FAIL = "SHIFT_SEARCH_FAIL"


// LOCATION DATA CONST
export const LOCATION_REQUEST = "LOCATION_REQUEST"
export const LOCATION_SUCCESS = "LOCATION_SUCCESS"
export const LOCATION_FAIL = "LOCATION_FAIL"


// HOLIDAY DATA CONST
export const HOLIDAY_REQUEST = "HOLIDAY_REQUEST"
export const HOLIDAY_SUCCESS = "HOLIDAY_SUCCESS"
export const HOLIDAY_FAIL = "HOLIDAY_FAIL"


// TRAINING DATA CONST
export const TRAINING_REQUEST = "TRAINING_REQUEST"
export const TRAINING_SUCCESS = "TRAINING_SUCCESS"
export const TRAINING_FAIL = "TRAINING_FAIL"


// CLOCK IN CONST
export const CLOCK_IN_REQUEST = "CLOCK_IN_REQUEST"
export const CLOCK_IN_SUCCESS = "CLOCK_IN_SUCCESS"
export const CLOCK_IN_FAIL = "CLOCK_IN_FAIL"
export const CLOCK_OUT_REQUEST = "CLOCK_OUT_REQUEST"
export const CLOCK_OUT_SUCCESS = "CLOCK_OUT_SUCCESS"
export const CLOCK_OUT_FAIL = "CLOCK_OUT_FAIL"
export const CLOCK_EXIST_REQUEST = "CLOCK_EXIST_REQUEST"
export const CLOCK_EXIST_SUCCESS = "CLOCK_EXIST_SUCCESS"
export const CLOCK_EXIST_FAIL = "CLOCK_EXIST_FAIL"
export const CLOCK_STATUS_REQUEST = "CLOCK_STATUS_REQUEST"
export const CLOCK_STATUS_SUCCESS = "CLOCK_STATUS_FAIL"
export const CLOCK_STATUS_FAIL = "CLOCK_EXIST_FAIL"
export const CLOCK_MONTHLY_DATA_REQUEST = "CLOCK_MONTHLY_DATA_REQUEST"
export const CLOCK_MONTHLY_DATA_SUCCESS = "CLOCK_MONTHLY_DATA_SUCCESS"
export const CLOCK_MONTHLY_DATA_FAIL = "CLOCK_MONTHLY_DATA_FAIL"


// USER DATA CONST
export const ANNIVARSARY_REQUEST = "ANNIVARSARY_REQUEST"
export const ANNIVARSARY_SUCCESS = "ANNIVARSARY_SUCCESS"
export const ANNIVARSARY_FAIL = "ANNIVARSARY_FAIL"


// SALARY DATA CONST
export const SALARY_REQUEST = "SALARY_REQUEST"
export const SALARY_SUCCESS = "SALARY_SUCCESS"
export const SALARY_FAIL = "SALARY_FAIL"


// OVERTIME DATA CONST
export const OVERTIME_REQUEST = "OVERTIME_REQUEST"
export const OVERTIME_SUCCESS = "OVERTIME_SUCCESS"
export const OVERTIME_FAIL = "OVERTIME_FAIL"
export const OVERTIME_TABLE_REQUEST = "OVERTIME_TABLE_REQUEST"
export const OVERTIME_TABLE_SUCCESS = "OVERTIME_TABLE_SUCCESS"
export const OVERTIME_TABLE_FAIL = "OVERTIME_TABLE_FAIL"


// LEAVE DATA CONST
export const LEAVE_ANNUAL_STATUS_REQUEST = "LEAVE_ANNUAL_STATUS_REQUEST"
export const LEAVE_ANNUAL_STATUS_SUCCESS = "LEAVE_ANNUAL_STATUS_SUCCESS"
export const LEAVE_ANNUAL_STATUS_FAIL = "LEAVE_ANNUAL_STATUS_FAIL"
export const LEAVE_REQUEST = "LEAVE_REQUEST"
export const LEAVE_SUCCESS = "LEAVE_SUCCESS"
export const LEAVE_FAIL = "LEAVE_FAIL"
export const LEAVE_CREATE_REQUEST = "LEAVE_CREATE_REQUEST"
export const LEAVE_CREATE_SUCCESS = "LEAVE_CREATE_SUCCESS"
export const LEAVE_CREATE_FAIL = "LEAVE_CREATE_FAIL"


// MAIL DATA CONST
export const MAIL_REQUEST = "MAIL_REQUEST"
export const MAIL_SUCCESS = "MAIL_SUCCESS"
export const MAIL_FAIL = "MAIL_FAIL"
export const MAIL_BY_ID_REQUEST = "MAIL_BY_ID_REQUEST"
export const MAIL_BY_ID_SUCCESS = "MAIL_BY_ID_SUCCESS"
export const MAIL_BY_ID_FAIL = "MAIL_BY_ID_FAIL"




