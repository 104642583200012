import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMailByMailId } from "../actions/mailActions"

export const HeaderMessageModel = ({ showDropDownMessage }) => {
    const mail = useSelector((state) => state.mail);
    const auth = useSelector((state) => state.auth);
    const [windowWidth, setWindowWidth] = useState(0)
    console.log(windowWidth==576,"windowWidth==576");
useEffect(() => {
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth);
    });
    
  }, [window.innerWidth]);
  const dispatch = useDispatch()
  const mailIndividual = async (idMail) => {
   dispatch(getMailByMailId({mailId: idMail}))
}

  let arr =[]

   //  let data = [
   //      {
   //          username: 'Richard Mile',
   //          message: 'Lorem ipsum dollor',
   //          time: 4
   //      },
   //      {
   //          username: 'John Doe',
   //          message: 'Lorem ipsum dollor',
   //          time: 10
   //      },
   //      {
   //          username: 'Tarah Shropshire',
   //          message: 'Lorem ipsum dollor',
   //          usertime: 20
   //      },
   //      {
   //          username: 'Mike Litorus',
   //          message: 'Lorem ipsum dollor',
   //          time: 4
   //      },
        
   //  ]

    return (
      
      <div className={`dropdown-menu-message dropdown-menu notifications ${showDropDownMessage ? 'show' : '.hide'} `}>
      <div className="topnav-dropdown-header">
         <span className="notification-title">Messages</span>

         {/* abhi set krna hai */}
         <Link to="javascript:void(0)" className="clear-noti"> Clear All </Link>
      </div>
      <div className="noti-content">
         <ul className="notification-list">
            {
               mail.mailData &&
               mail.mailData.map((item,i)=>{
                  console.log(item.id)
                  return (
                     <li className="notification-message" key={i} >
                        <a >
                        {/* <Link to="/inbox">    */}
                           <div className="list-item">
                              <div className="list-left">
                                 <span className="avatar">
                                    <img alt="" src={require('../assets/img/avatar-01.jpg')} />
                                 </span>
                              </div>
                              <div className="list-body">
                                 {/* { */}
                                    <span className="message-author">
                                       {
                                          item.members.forEach(e => {
                                             if (e.id !== auth.user.id){
                                                arr.push(e.firstName)
                                             }
                                             console.log(arr)
                                          })
                                          
                                          }{arr}
                                    </span>
                                 {/* } */}
                                 <span className="message-time">{item.messages[item.messages.length-1].timestamps}</span>
                                 <div className="clearfix"></div>
                                 <span className="message-content">{item.messages[item.messages.length-1].message}</span>
                              </div>
                           </div>
                        {/* </Link> */}
                        </a>
                     </li>
                  );
               }
               
            )}
            
 
         </ul>
      </div>
      <div className="topnav-dropdown-footer">
         <Link to="/inbox">View all Messages</Link>
      </div>
   </div>
    )
}
