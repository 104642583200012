import axios from "axios";
import {
    SHIFT_DATA_REQUEST,
    SHIFT_DATA_SUCCESS,
    SHIFT_DATA_FAIL,
    SHIFT_DASBOARD_REQUEST,
    SHIFT_DASBOARD_SUCCESS,
    SHIFT_DASBOARD_FAIL,
    SHIFT_PROFILE_REQUEST,
    SHIFT_PROFILE_SUCCESS,
    SHIFT_PROFILE_FAIL,
    SHIFT_SEARCH_REQUEST,
    SHIFT_SEARCH_SUCCESS,
    SHIFT_SEARCH_FAIL,
    SERVERADDRESS,
} from "../constants"

// SHIFT AND SCHEDULING PAGE DATA
export const shiftPageData = (obj) => async (dispatch) => {
    dispatch({
        type: SHIFT_DATA_REQUEST,
    });
    try {
        console.log(obj, "hgjkbhllbagvvsghvabj")
        const { data } = await axios.post(`${SERVERADDRESS}/v1/shift/getShiftByUserIdDiffViews`, obj);
        localStorage.setItem("dailyShift", JSON.stringify(data[0].dailyShift));
        localStorage.setItem("weeklyShift", JSON.stringify(data[0].weeklyShift));
        localStorage.setItem("monthlyShift", JSON.stringify(data[0].monthlyShift));
        // const res = await axios.post(`${SERVERADDRESS}v1/shift/getShiftByUserIdDiffViews`, obj);
        // console.log("------------->", res)
        dispatch({
            type: SHIFT_DATA_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SHIFT_DATA_FAIL,
            payload: err,
        });
    }
};

// SHIFT DASHBOARD DATA
export const shiftDashboard = (obj) => async (dispatch) => {
    dispatch({
        type: SHIFT_DASBOARD_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/shift/getShiftByUserId`, obj);
        localStorage.setItem("thisWeek", JSON.stringify(data[0].thisWeek));
        localStorage.setItem("tomorrowShift", JSON.stringify(data[0].tomorrowShift));
        localStorage.setItem("activeLocationThisWeek", JSON.stringify(data[0].activeLocationThisWeek));
        localStorage.setItem("activeLocationThisMonth", JSON.stringify(data[0].activeLocationThisMonth));
        dispatch({
            type: SHIFT_DASBOARD_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SHIFT_DASBOARD_FAIL,
            payload: err,
        });
    }
};

// SHIFT PROFILE DATA
export const shiftProfile = (obj) => async (dispatch) => {
    dispatch({
        type: SHIFT_PROFILE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/shift/shiftProfile`, obj);
        // localStorage.setItem("upcomingShift", JSON.stringify(data[0].upcoming));
        // localStorage.setItem("recentShift", JSON.stringify(data[0].recent));
        console.log(data[0].recent)
        localStorage.setItem("upcomingShift", JSON.stringify(data[0].upcoming));
        localStorage.setItem("recentShift", JSON.stringify(data[0].recent));
        dispatch({
            type: SHIFT_PROFILE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SHIFT_PROFILE_FAIL,
            payload: err,
        });
    }
};

// SHIFT SEARCH BY USING SHIFT SEARCH KPI
export const shiftSearchFilter = (obj) => async (dispatch) => {
    dispatch({
        type: SHIFT_SEARCH_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/shiftSearchFilter`, obj);
        dispatch({
            type: SHIFT_SEARCH_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SHIFT_SEARCH_FAIL,
            payload: err,
        });
    }
};

