import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import logo from "../../assets/img/logo.png"
import avatar2 from "../../assets/img/avatar-02.jpg"
import avatar3 from "../../assets/img/avatar-03.jpg"
import avatar6 from "../../assets/img/avatar-06.jpg"
import avatar17 from "../../assets/img/avatar-17.jpg"
import avatar9 from "../../assets/img/avatar-09.jpg"
import avatar13 from "../../assets/img/avatar-13.jpg"
import avatar5 from "../../assets/img/avatar-05.jpg"
import avatar8 from "../../assets/img/avatar-08.jpg"
import avatar21 from "../../assets/img/avatar-21.jpg"
import usFlag from "../../assets/img/us.png"
import frenchFlag from "../../assets/img/fr.png"
import spainFlag from "../../assets/img/es.png"
import germanFlag from '../../assets/img/de.png'
import EmployeeSidebar from './EmployeeSidebar'
import { HeaderNotificationModel } from '../HeaderNotificationModel'
import { HeaderMessageModel } from '../HeaderMessageModel'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from "../../actions/authActions"
// import { userSelector } from '../../app/features/loginSlice'
// import { myProfile } from '../../app/features/myProfile/myProfileSlice'


const EmployeeHeader = () => {
   // new line add krhe hain 3/8/2022

// const {userById} = useSelector(
//    userSelector
// ) 
   const dispatch = useDispatch()
   const [showDropDown, setshowDropDown] = useState(false)
   const [showDropDownFlag, setshowDropDownFlag] = useState(false)
   const [showDropDownMessage, setshowDropDownMessage] = useState(false)
   const [opensidedrawer, setopensidedrawer] = useState(false)
   const [openDrop, setopenDrop] = useState(false)
   const auth = useSelector((state) => state.auth);
   // const dispatch = useDispatch()
   let navigate = useNavigate()
   const navigateUser = () => {
      navigate('/login')
  }
   const logout = async (e) => {
      await dispatch(userLogout({refreshToken: auth.tokens.refresh.token}, navigateUser))
   }
   const openDropDown = () => {
      setshowDropDown((state) => !state)
      setshowDropDownMessage(false)
      setshowDropDownFlag(false)
      setopenDrop(false)
   }
   const openDropDownFlag = () => {
      setshowDropDownFlag((state) => !state)
      setshowDropDown(false)
      setshowDropDownMessage(false)
      setopenDrop(false)
   }
   const openDropDownMessage = () => {
      setshowDropDownMessage((state) => !state)
      setshowDropDown(false)
      setopenDrop(false)
      setshowDropDownFlag(false)
   }
   const openDropDownMenu = () => { 
      setopenDrop((state) => !state)
      setshowDropDown(false)
      setshowDropDownFlag(false)
      setshowDropDownMessage(false)
     }

   const toggleDriver = () => { 
      setopensidedrawer((state) => !state)
   }
   //   new line 3/8/2022
    
//    const getprofileByID =   (id) => {
//       dispatch(myProfile(id))
//   }
   return (
      <>
    
         <div className="main-wrapper">
            {/* <div id="loader-wrapper">
              <div id="loader">
                 <div className="loader-ellips">
                    <span className="loader-ellips__dot"></span>
                    <span className="loader-ellips__dot"></span>
                    <span className="loader-ellips__dot"></span>
                    <span className="loader-ellips__dot"></span>
                 </div>
              </div>
           </div> */}
            <div className="header">
               <div className="header-left">
                  <NavLink to="/dashboard" className="logo">
                     <img src={logo} height="60" alt="" />
                  </NavLink>
               </div>

               <div className="page-title-box">
                  <h3>Riyo Work</h3>
               </div>

               {/* abhi set krna hai */}
               <NavLink id="mobile_btn" className="mobile_btn"
                  to="#"
                onClick={toggleDriver}><i className="fa fa-bars"></i></NavLink>
               <ul className="nav user-menu">





{/* // uncomment krna hai */}




                  {/* <li className="nav-item">
                     <div className="top-nav-search"> */}
                        {/* abhi set krna hai */}
                        {/* <NavLink to="javascript:void(0);" className="responsive-search">
                           <i className="fa fa-search"></i>
                        </NavLink> */}

                        {/* abhi set krna hai */}
                        {/* <form action="search.html">
                           <input className="form-control" type="text" placeholder="Search here" />
                           <button className="btn" type="submit"><i className="fa fa-search"></i></button>
                        </form>
                     </div>
                  </li> */}
{/* yaha openDropDownFlag banaya hai aur box ko align kiya hai*/}
                  {/* <li className="nav-item dropdown has-arrow flag-nav">
                  <NavLink className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" onClick={openDropDownFlag}>
                  <img src={usFlag} alt="" height="20"/> <span>English</span>
                  </NavLink>
                  { showDropDownFlag === true ?
                     // dropdown-menu-right 
                     <div className={`dropdown-menu 
                     dropdown-flag
                     show`}>
                     <NavLink to="javascript:void(0);" className="dropdown-item">
                     <img src={usFlag} alt="" height="16"/> English
                     </NavLink>
                     <NavLink to="javascript:void(0);" className="dropdown-item">
                     <img src={frenchFlag} alt="" height="16"/> French
                     </NavLink>
                     <NavLink to="javascript:void(0);" className="dropdown-item">
                     <img src={spainFlag} alt="" height="16"/> Spanish
                     </NavLink>
                     <NavLink to="javascript:void(0);" className="dropdown-item">
                     <img src={germanFlag} alt="" height="16"/> German
                     </NavLink>
                  </div>
                  :
                  <div></div>
                  }
               </li> */}

                  {/* <li className="nav-item dropdown"> */}

                     {/* abhi set krna hai */}
                     {/* <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown" onClick={openDropDown}>
                        <i className="fa fa-bell-o"></i> <span className="badge badge-pill">3</span>
                     </NavLink>
                     <HeaderNotificationModel showDropDown={showDropDown} />

                  </li> */}







                  <li className="nav-item dropdown">

                     {/* abhi set krna hai */}
                     <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown" onClick={openDropDownMessage}>
                        <i className="fa fa-comment-o"></i> <span className="badge badge-pill">8</span>
                     </NavLink>
                     <HeaderMessageModel showDropDownMessage={showDropDownMessage}/>
                  </li>
                  <li className="nav-item dropdown has-arrow main-drop" onClick={openDropDownMenu}>

                     {/* abhi set krna hai */}
                     <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                        <span className="user-img"><img src={avatar21} alt="" />
                           <span className="status online"></span></span>
                        <span>Employee</span>
                     </NavLink>
                      {/* ye new line add ki ha yaha par kam kiya ha */}
                     <div className={`dropdown-menu dropdown-profile ${openDrop?'show':'hide'}`}>
                        {/* new code<NavLink className="dropdown-item" to="/profile" onClick={()=>getprofileByID(userById)}>My Profile</NavLink> */}
                      <NavLink className="dropdown-item" to="/profile">My Profile</NavLink>
                        {/* old line is committed below */}
                        {/* <NavLink className="dropdown-item" to="/profile">My Profile</NavLink> */}
                        <NavLink className="dropdown-item" to="/settings">Settings</NavLink>

                        {/* abhi set krna hai */}
                     <a className="dropdown-item" style={{cursor: "pointer"}} onClick={logout}>Logout</a>

                     </div>
                  </li>
               </ul>
               <div className="dropdown mobile-user-menu" onClick={openDropDownMenu}>
                  <NavLink to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></NavLink>
                  <div className={`dropdown-menu dropdown-menu-right ${openDrop?'show':'hide'}`}>
                     <NavLink className="dropdown-item" to="/profile" >My Profile</NavLink>
                     <NavLink className="dropdown-item" to="/settings">Settings</NavLink>

                     {/* abhi set krna hai */}
                     {/* <NavLink className="dropdown-item" to="login">Logout</NavLink> */}
                     <a className="dropdown-item" style={{cursor: "pointer"}} onClick={logout}>Logout</a>
                  </div>
               </div>
               <div className={`sidebar ${opensidedrawer?'showsidebar':'hidesidebar'}`} id="sidebar">
                  <div className="sidebar-inner slimscroll">
                     <div id="sidebar-menu" className="sidebar-menu">
                        <ul>

                           <li>
                              <NavLink to="/dashboard" className="noti-dot"><i className="la la-dashboard"></i> <span> Dashboard</span></NavLink>
                           </li>

                           <li><NavLink to="/shift-scheduling"><i className="la la-users"></i><span>Shift &amp; Schedule</span></NavLink></li>
                           <li><NavLink to="/attendance"><i className="la la-clock-o"></i><span>Time Clock</span></NavLink></li>
                           <li><NavLink to="/leaves"><i className="la la-external-link-square"></i><span>Leaves </span><span className="badge badge-pill bg-primary float-right">1</span></NavLink></li>
                           <li><NavLink to="/training"><i className="la la-graduation-cap"></i>  <span> Training </span></NavLink></li>

                            {/* <li className="menu-title">
                              <span>My Stuff</span>
                           </li>
                           <li>
                              <NavLink to="/salary"> <i className="la la-money"></i> <span> Salary</span></NavLink></li>
                           <li>
                              <NavLink to="/salary-slip"><i className="la la-file-alt"></i> <span>Salary Slip</span></NavLink>
                           </li>
                           <li><NavLink to="/file-manager"><i className="la la-copy"></i><span>My Files</span></NavLink></li>  

                           <li>
                              <NavLink to="index.html"><i className="la la-power-off"></i> <span>Logout</span></NavLink>
                           </li> */}
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className={`sidebar-overlay ${opensidedrawer?'opened': ''}`}></div>
      </>
   )
}

export default EmployeeHeader

// import React, { useState } from 'react'
// import { NavLink } from 'react-router-dom'
// import logo from "../../assets/img/logo.png"
// import avatar2 from "../../assets/img/avatar-02.jpg"
// import avatar3 from "../../assets/img/avatar-03.jpg"
// import avatar6 from "../../assets/img/avatar-06.jpg"
// import avatar17 from "../../assets/img/avatar-17.jpg"
// import avatar9 from "../../assets/img/avatar-09.jpg"
// import avatar13 from "../../assets/img/avatar-13.jpg"
// import avatar5 from "../../assets/img/avatar-05.jpg"
// import avatar8 from "../../assets/img/avatar-08.jpg"
// import avatar21 from "../../assets/img/avatar-21.jpg"
// import usFlag from "../../assets/img/us.png"
// import frenchFlag from "../../assets/img/fr.png"
// import spainFlag from "../../assets/img/es.png"
// import germanFlag from '../../assets/img/de.png'
// import EmployeeSidebar from './EmployeeSidebar'
// import { HeaderNotificationModel } from '../HeaderNotificationModel'
// import { HeaderMessageModel } from '../HeaderMessageModel'
// import { useDispatch, useSelector } from 'react-redux'
// import { userSelector } from '../../app/features/loginSlice'
// // import { myProfile } from '../../app/features/myProfile/myProfileSlice'


// const EmployeeHeader = () => {
//    // new line add krhe hain 3/8/2022

// const {userById} = useSelector(
//    userSelector
// ) 
//    const dispatch = useDispatch()
//    const [showDropDown, setshowDropDown] = useState(false)
//    const [showDropDownFlag, setshowDropDownFlag] = useState(false)
//    const [showDropDownMessage, setshowDropDownMessage] = useState(false)
//    const [opensidedrawer, setopensidedrawer] = useState(false)
//    const [openDrop, setopenDrop] = useState(false)

//    const openDropDown = () => {
//       setshowDropDown((state) => !state)
//       setshowDropDownMessage(false)

//    }
//    const openDropDownFlag = () => {
//       setshowDropDownFlag((state) => !state)
//       // setshowDropDownMessage(false)

//    }
//    const openDropDownMessage = () => {
//       setshowDropDown(false)
//       setshowDropDownMessage((state) => !state)
//    }
//    const toggleDriver = () => { 
//       setopensidedrawer((state) => !state)
      
//    }
//    const openDropDownMenu = () => { 
//       setopenDrop((state) => !state)
      
//      }
//    //   new line 3/8/2022
    
// //    const getprofileByID =   (id) => {
// //       dispatch(myProfile(id))
// //   }
//    return (
//       <>
    
//          <div className="main-wrapper">
//             {/* <div id="loader-wrapper">
//               <div id="loader">
//                  <div className="loader-ellips">
//                     <span className="loader-ellips__dot"></span>
//                     <span className="loader-ellips__dot"></span>
//                     <span className="loader-ellips__dot"></span>
//                     <span className="loader-ellips__dot"></span>
//                  </div>
//               </div>
//            </div> */}
//             <div className="header">
//                <div className="header-left">
//                   <NavLink to="/dashboard" className="logo">
//                      <img src={logo} height="60" alt="" />
//                   </NavLink>
//                </div>

//                <div className="page-title-box">
//                   <h3>Riyo Work</h3>
//                </div>

//                {/* abhi set krna hai */}
//                <NavLink id="mobile_btn" className="mobile_btn"
//                   to="#"
//                 onClick={toggleDriver}><i className="fa fa-bars"></i></NavLink>
//                <ul className="nav user-menu">
//                   <li className="nav-item">
//                      <div className="top-nav-search">
//                         {/* abhi set krna hai */}
//                         <NavLink to="javascript:void(0);" className="responsive-search">
//                            <i className="fa fa-search"></i>
//                         </NavLink>

//                         {/* abhi set krna hai */}
//                         <form action="search.html">
//                            <input className="form-control" type="text" placeholder="Search here" />
//                            <button className="btn" type="submit"><i className="fa fa-search"></i></button>
//                         </form>
//                      </div>
//                   </li>
// {/* yaha openDropDownFlag banaya hai aur box ko align kiya hai*/}
//                   <li className="nav-item dropdown has-arrow flag-nav">
//                   <NavLink className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" onClick={openDropDownFlag}>
//                   <img src={usFlag} alt="" height="20"/> <span>English</span>
//                   </NavLink>
//                   { showDropDownFlag === true ?
//                      // dropdown-menu-right 
//                      <div className={`dropdown-menu 
//                      dropdown-flag
//                      show`}>
//                      <NavLink to="javascript:void(0);" className="dropdown-item">
//                      <img src={usFlag} alt="" height="16"/> English
//                      </NavLink>
//                      <NavLink to="javascript:void(0);" className="dropdown-item">
//                      <img src={frenchFlag} alt="" height="16"/> French
//                      </NavLink>
//                      <NavLink to="javascript:void(0);" className="dropdown-item">
//                      <img src={spainFlag} alt="" height="16"/> Spanish
//                      </NavLink>
//                      <NavLink to="javascript:void(0);" className="dropdown-item">
//                      <img src={germanFlag} alt="" height="16"/> German
//                      </NavLink>
//                   </div>
//                   :
//                   <div></div>
//                   }
//                </li>

//                   <li className="nav-item dropdown">

//                      {/* abhi set krna hai */}
//                      <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown" onClick={openDropDown}>
//                         <i className="fa fa-bell-o"></i> <span className="badge badge-pill">3</span>
//                      </NavLink>
//                      <HeaderNotificationModel showDropDown={showDropDown} />

//                   </li>
//                   <li className="nav-item dropdown">

//                      {/* abhi set krna hai */}
//                      <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown" onClick={openDropDownMessage}>
//                         <i className="fa fa-comment-o"></i> <span className="badge badge-pill">8</span>
//                      </NavLink>
//                      <HeaderMessageModel showDropDownMessage={showDropDownMessage}/>
//                   </li>
//                   <li className="nav-item dropdown has-arrow main-drop" onClick={openDropDownMenu}>

//                      {/* abhi set krna hai */}
//                      <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
//                         <span className="user-img"><img src={avatar21} alt="" />
//                            <span className="status online"></span></span>
//                         <span>Employee</span>
//                      </NavLink>
//                       {/* ye new line add ki ha yaha par kam kiya ha */}
//                      <div className={`dropdown-menu dropdown-profile ${openDrop?'show':'hide'}`}>
//                         {/* new code<NavLink className="dropdown-item" to="/profile" onClick={()=>getprofileByID(userById)}>My Profile</NavLink> */}
//                       <NavLink className="dropdown-item" to="/profile">My Profile</NavLink>
//                         {/* old line is committed below */}
//                         {/* <NavLink className="dropdown-item" to="/profile">My Profile</NavLink> */}
//                         <NavLink className="dropdown-item" to="/settings">Settings</NavLink>

//                         {/* abhi set krna hai */}
//                         <NavLink className="dropdown-item" to="/login">Logout</NavLink>
//                      </div>
//                   </li>
//                </ul>
//                <div className="dropdown mobile-user-menu" onClick={openDropDownMenu}>
//                   <NavLink to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></NavLink>
//                   <div className={`dropdown-menu dropdown-menu-right ${openDrop?'show':'hide'}`}>
//                      <NavLink className="dropdown-item" to="/profile" >My Profile</NavLink>
//                      <NavLink className="dropdown-item" to="/settings">Settings</NavLink>

//                      {/* abhi set krna hai */}
//                      <NavLink className="dropdown-item" to="login.html">Logout</NavLink>
//                   </div>
//                </div>
//                <div className={`sidebar ${opensidedrawer?'showsidebar':'hidesidebar'}`} id="sidebar">
//                   <div className="sidebar-inner slimscroll">
//                      <div id="sidebar-menu" className="sidebar-menu">
//                         <ul>

//                            <li>
//                               <NavLink to="/dashboard" className="noti-dot"><i className="la la-dashboard"></i> <span> Dashboard</span></NavLink>
//                            </li>

//                            <li><NavLink to="/shift-scheduling"><i className="la la-users"></i><span>Shift &amp; Schedule</span></NavLink></li>
//                            <li><NavLink to="/attendance"><i className="la la-clock-o"></i><span>Time Clock</span></NavLink></li>
//                            <li><NavLink to="/leaves"><i className="la la-external-link-square"></i><span>Leaves </span><span className="badge badge-pill bg-primary float-right">1</span></NavLink></li>
//                            <li><NavLink to="/training"><i className="la la-graduation-cap"></i>  <span> Training </span></NavLink></li>

//                             {/* <li className="menu-title">
//                               <span>My Stuff</span>
//                            </li>
//                            <li>
//                               <NavLink to="/salary"> <i className="la la-money"></i> <span> Salary</span></NavLink></li>
//                            <li>
//                               <NavLink to="/salary-slip"><i className="la la-file-alt"></i> <span>Salary Slip</span></NavLink>
//                            </li>
//                            <li><NavLink to="/file-manager"><i className="la la-copy"></i><span>My Files</span></NavLink></li>  

//                            <li>
//                               <NavLink to="index.html"><i className="la la-power-off"></i> <span>Logout</span></NavLink>
//                            </li> */}
//                         </ul>
//                      </div>
//                   </div>
//                </div>
//             </div>
//          </div>
//          <div className={`sidebar-overlay ${opensidedrawer?'opened': ''}`}></div>
//       </>
//    )
// }

// export default EmployeeHeader

